<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item :class="localAula.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Professor (a)</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.professor?.nome }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Data</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.data_formatada }}
        </p>
        <v-list-item-title class="mt-4">Horário(s) Selecionado(s)</v-list-item-title>
        <div v-if="localAula.horarios">
          <div v-if="Object.keys(horariosSelecionados).length">
            <div v-for="(descricao, id) in horariosSelecionados" :key="id">
              <p class="font-weight-light text-justify">● {{ descricao }}</p>
            </div>
          </div>
          <div v-else>
            <p class="font-weight-light text-justify">Nenhum horário selecionado</p>
          </div>
        </div>
        <div v-else>
          <p class="font-weight-light text-justify">
            ● {{ localAula.horario ? localAula.horario.descricao : "[- - -]" }}
          </p>
        </div>

        <div v-for="(item, index) of $constants.listCamposAulasLancadas" :key="index">
          <div v-if="index !== 'campos_de_experiencias'">
            <v-list-item-title v-if="localAula[index]" class="mt-4">{{ item }}</v-list-item-title>
            <p v-if="localAula[index]" class="font-weight-light text-justify">
              {{ localAula[index] ? localAula[index] : "- - -" }}
            </p>
          </div>

          <div v-else>
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-2">{{
              item
            }}</v-list-item-title>
            <div v-for="(el, index) of localAula[index]" :key="index">
              <p v-if="el" :key="index" class="font-weight-light text-justify">● {{ el }}</p>
            </div>
          </div>
        </div>

        <div v-if="localAula.e_aula_infantil === 1">
          <v-list-item-title class="mt-4">Habilidades</v-list-item-title>
          <div v-for="(item, index) in localAula.habilidades" :key="index">
            <v-card color="green" class="pt-1 mt-1">
              <p class="text-justify mb-2 mt-2 ml-2 mr-2">
                {{ item.descricao }}
              </p>
              <v-chip
                color="amber darken-3"
                class="font-weight-light text-justify mb-2 mt-2 ml-2 mr-2"
                >{{ item.parent_descricao }}</v-chip
              >
            </v-card>
          </div>

          <v-list-item-title class="mt-4 mb-1">Rotinha permanente</v-list-item-title>
          <div v-for="(item, index) in localAula.rotina_permanente" :key="index">
            <p class="text-justify font-weight-light">{{ item }}</p>
          </div>

          <v-list-item-title class="mt-4">Recursos</v-list-item-title>
          <div v-for="(item, index) in localAula.recursos" :key="index">
            <v-chip color="amber darken-3" class="text-justify font-weight-light mb-1">{{
              item
            }}</v-chip>
          </div>
          <div v-if="localAula.add_recursos">
            <v-list-item-title class="mt-4 mb-1">Outros recursos</v-list-item-title>
            <div v-for="(item, index) in localAula.novos_recursos" :key="index">
              <v-chip color="amber darken-3" class="text-justify font-weight-light mb-1">{{
                item
              }}</v-chip>
            </div>
          </div>
        </div>

        <v-list-item-title class="">Situação</v-list-item-title>
        <v-list-item-title>
          <v-chip label :color="localAula.color">
            {{ localAula.descricaoSituacao }}
          </v-chip>
        </v-list-item-title>
        <v-list-item-title class="mt-4">Motivo</v-list-item-title>
        <p class="font-weight-light">
          {{ localAula.motivo ? localAula.motivo : "Sem motivo." }}
        </p>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
    horario: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
    localHorarios() {
      return this.horario;
    },
    horariosSelecionados() {
      const horariosMap = this.localHorarios.reduce((map, horario) => {
        map[horario.id] = horario.descricao;
        return map;
      }, {});
      return this.localAula.horarios.reduce((result, id) => {
        if (id !== null && horariosMap[id]) {
          result[id] = horariosMap[id];
        }
        return result;
      }, {});
    },

    parsedHabilidades() {
      try {
        // console.log("Habilidades antes do parse:", this.localAula.habilidades);
        if (typeof this.localAula.habilidades === "string") {
          const habilidadesString = this.localAula.habilidades.trim();
          if (habilidadesString.startsWith("[") && habilidadesString.endsWith("]")) {
            return JSON.parse(habilidadesString);
          }
          // console.error("Formato de string JSON inválido:", habilidadesString);
          return [];
        }
        // console.error("Habilidades não é uma string:", this.localAula.habilidades);
        return [];
      } catch (e) {
        // console.error("Erro ao parsear habilidades:", e);
        return [];
      }
    },
  },
  data() {
    return {
      localDrawer: this.value,
      camposDeExperiencias: [
        {
          apelido: "",
          descricao: "",
          parent_descricao: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
